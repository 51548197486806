import React from 'react'
import Carousel from '@components/Carousel'
import cx from 'classnames'

export default function LatestGuides({
    block: { __typename, body, title, guides: items },
    className,
    locale,
}) {
    return (
        <div className={cx(className)}>
            <Carousel
                {...{
                    title,
                    body,
                    type: 'guide',
                    items,
                    locale
                }}
            />
        </div>
    )
}
